import "./publicGuideChatPage.scss";

const PublicGuideChatPage = () => {
  const url =
    process.env.REACT_APP_ENV === "production"
      ? "https://admin.mynota.io/public-chat"
      : "https://staging.admin.mynota.io/public-chat";
  return (
    <div className="nota-guide-page">
      <iframe
        src={url}
        width="100%"
        height={window.innerHeight}
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
};

export default PublicGuideChatPage;
